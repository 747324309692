import React from "react";

import BlockContent from "components/BlockContent";
import Box from "components/Box";
import ContactFormm from "components/ContactForm";

const style = {
  width: "100%",
  maxWidth: "lg",
  mx: "auto",
  mb: 6,
  px: 3,
};

const Form = ({ text, form, sx, isCompoundSectionChild }) => (
  <Box
    as={isCompoundSectionChild ? "div" : "section"}
    sx={{ ...style, ...(sx || {}) }}
  >
    <BlockContent as="header" blocks={text} sx={{ mb: 4 }} />
    <ContactFormm {...form} />
  </Box>
);

export default Form;
